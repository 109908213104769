import React, { useEffect, useState } from 'react';

const GoogleTable = () => {
  const [totalProfit, setTotalProfit] = useState(0);
  const [profitcnt, setProfitcnt] = useState(0);
  const [totalLoss, setTotalLoss] = useState(0);
  const [losscnt, setLosscnt] = useState(0);
  const [profitratio, setProfitratio] = useState(0);

  useEffect(() => {
    const drawTable = async () => {
      console.log("start");
      try {
        const response = await fetch('history.json');
        if (!response.ok) {
          console.log("Error");
          throw new Error(`HTTP Error! status: ${response.status}`);
        }
        const data = await response.json();
        data.sort((a, b) => new Date(b.day) - new Date(a.day));

        window.google.charts.load('current', { packages: ['table'] });
        window.google.charts.setOnLoadCallback(() => {
          console.log("Onload");
          var tableData = new window.google.visualization.DataTable();
          tableData.addColumn('string', '날짜 / 종목');
          tableData.addColumn('number', '최대 수익률 (%)');
          
          let _totalProfit = 0;
          let _profitcnt = 0;
          let _totalLoss = 0;
          let _losscnt = 0;

          data.forEach(item => {
            const profitRate = parseFloat(item.maxprofit).toFixed(2);
            if (item.maxprofit > 0) {
              _totalProfit += parseFloat(item.maxprofit);
              _profitcnt += 1;
            } else {
              _totalLoss += parseFloat(item.maxprofit);
              _losscnt += 1;
            }
            tableData.addRow([`${item.day} / ${item.jongmok}`, parseFloat(profitRate)]);
          });

          let _profitratio = _profitcnt / (_losscnt + _profitcnt) * 100;
          setTotalProfit(_totalProfit);
          setProfitcnt(_profitcnt);
          setTotalLoss(_totalLoss);
          setLosscnt(_losscnt);
          setProfitratio(_profitratio);

          var table = new window.google.visualization.Table(document.getElementById('table_div'));
          table.draw(tableData, { showRowNumber: true, width: '100%', height: '100%' });

          // 테이블 행 클릭 이벤트 추가
          window.google.visualization.events.addListener(table, 'select', async () => {
            var selection = table.getSelection();
            console.log("click");
            if (selection.length > 0) {
              var row = selection[0].row;
              var jongmokName = tableData.getValue(row, 0).split(' / ')[1];
              console.log(jongmokName);
              
              var jongmokCode = await getStockCode(jongmokName); // 주식 코드를 가져오기
              if (jongmokCode) {
                // const url = `https://www.tradingview.com/chart/d9CfzqkI/?symbol=${jongmokCode}&utm_source=www.tradingview.com&utm_medium=widget&utm_campaign=chart&utm_term=${jongmokCode}`;
                // window.open(url, '_blank'); // 새로운 탭에서 URL 열기
                initChart(jongmokCode);
              } else {
                console.error('종목 코드가 없습니다:', jongmokName);
              }
            }
          });
        });
      } catch (Error) {
        console.error('테이블을 그리는데 실패했습니다:', Error);
      }
    };
    drawTable();
  }, []);

  async function getStockCode(jongmokName) {
    try {
      const today = new Date();
      const formattedDate = today.toISOString().split('T')[0].replace(/-/g, '');
      
      const searchUrl = `https://data.krx.co.kr/comm/bldAttendant/getJsonData.cmd`;
      
      const response = await fetch(searchUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams({
          'bld': 'dbms/MDC/STAT/standard/MDCSTAT01901',
          'mktId': 'ALL',
          'trdDd': formattedDate,
          'searchText': jongmokName,
        })
      });
  
      if (!response.ok) {
        throw new Error('KRX API 호출 실패');
      }
  
      const data = await response.json();
      
      if (data.OutBlock_1 && data.OutBlock_1.length > 0) {
        // 첫 번째 검색 결과의 종목 코드 사용
        const stockCode = data.OutBlock_1[0].ISU_SRT_CD;
        return `KRX:${stockCode}`;
      } else {
        console.error('종목을 찾을 수 없습니다:', jongmokName);
        return null;
      }
    } catch (error) {
      console.error('주식 코드 가져오기 중 오류 발생:', error);
      // 에러 발생 시 종목명을 그대로 사용
      return `KRX:${jongmokName}`;
    }
  }
  

  // TradingView 차트를 초기화하는 함수
  const initChart = (symbol) => {
    new window.TradingView.widget({
      "container_id": "ccc",
      "autosize": true,
      "symbol": symbol,
      "interval": "D",
      "timezone": "Etc/UTC",
      "theme": "light",
      "style": "1",
      "locale": "en",
      "toolbar_bg": "#f1f3f6",
      "enable_publishing": false,
      "allow_symbol_change": true,
      "hide_side_toolbar": false,
      "withdateranges": true,
      "details": true
    });
  };

  return (
    <div>
      <div id="profit">익절: {totalProfit.toFixed(2)}</div>
      <div id="profitcnt">익절 수: {profitcnt}</div>
      <div id="loss">손절: {totalLoss.toFixed(2)}</div>
      <div id="losscnt">손절 수: {losscnt}</div>
      <div id="ratio" style={{ margin: 0 }}>성공률: {profitratio.toFixed(2)}%</div>
      <div id="table_div" style={{ width: '100%', height: '500px', overflowX: 'auto', overflowY: 'auto', marginTop: 0 }}>
      </div>
      <div id="ccc" style={{ width: '100%', height: '500px' }}></div> {/* 차트를 표시할 div */}
    </div>
  );
};

export default GoogleTable;
